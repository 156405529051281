<template>
  <v-dialog :value="value" @input="close" max-width="600px">
    <v-card>
      <!-- Title -->
      <v-card-title>
        <h3 v-if="item">{{ $t("products.mainDialog.edit.title") }}</h3>
        <h3 v-else>{{ $t("products.mainDialog.add.title") }}</h3>
      </v-card-title>

      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row>
            <!-- Name -->
            <v-col cols="12" class="mt-12">
              <v-text-field
                outlined
                hide-details
                :rules="requiredRules"
                :label="$t('products.labels.name')"
                maxlength="50"
                v-model="form.name"
              />
            </v-col>

            <!-- Public name -->
            <v-col cols="12" class="mt-12">
              <v-text-field
                outlined
                hide-details
                :label="$t('products.labels.publicName')"
                maxlength="50"
                v-model="form.publicName"
              />
            </v-col>

            <!-- Account -->
            <v-col cols="12">
              <SelectAccount
                :value="form.account"
                :is-required="true"
                @input="onInputAccount"
              />
            </v-col>

            <!-- Type -->
            <v-col cols="12">
              <v-autocomplete
                :items="$t('constants.productTypes.list')"
                v-model="form.type"
                :label="$t('products.labels.type')"
                outlined
                hide-details
              />
            </v-col>

            <!-- Group -->
            <v-col cols="12">
              <v-text-field
                hide-details
                outlined
                v-model="form.group"
                :label="$t('products.labels.group')"
              />
            </v-col>

            <!-- Timeless -->
            <v-col cols="12">
              <v-switch
                hide-details
                outlined
                v-model="form.isTimeless"
                :label="$t('products.labels.isTimeless')"
              />
            </v-col>

            <v-col cols="12">
              <AlertBar />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <ButtonBarFormDialog
          class="mx-3"
          :is-loading-btn-save="isLoadingBtn.btnSave"
          @submit="customSubmit"
          @cancel="close"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import dialogMixin from "@/mixins/dialog";
import formRules from "@/mixins/formRules";

export default {
  name: "ProductMainDialog",

  mixins: [dialogMixin, formRules],

  components: {
    SelectAccount: () => import("@/components/Common/Inputs/SelectAccount"),
    AlertBar: () => import("@/components/Common/Bars/AlertBar"),
    ButtonBarFormDialog: () =>
      import("@/components/Common/Buttons/ButtonBarFormDialog"),
  },

  data() {
    return {
      isLoadingBtn: { btnSave: false },

      defaultForm: {
        name: "",
        publicName: "",
        type: null,
        group: "",
        account: null,
        isTimeless: false,
      },
    };
  },

  methods: {
    customSubmit() {
      if (this.$refs.form.validate()) {
        this.isLoadingBtn.btnSave = true;

        this.shortcutFormObjects(["account"]);
        if (this.item) this.submitEdit();
        else this.submitAdd();
      }
    },

    submitAdd() {
      this.$http
        .post(`/products`, this.form, {
          headers: { Authorization: "Bearer " + this.$session.get("jwt") },
        })
        .then((res) => {
          this.$store.commit(
            "toast/showSuccess",
            this.$t("products.mainDialog.add.success")
          );
          this.close(true);
        })
        .catch((err) => {
          this.$store.commit("alert/showErrorAxios", err);
        })
        .finally(() => {
          this.isLoadingBtn.btnSave = false;
        });
    },

    submitEdit() {
      this.$http
        .put(`/products/${this.item.id}`, this.form, {
          headers: { Authorization: "Bearer " + this.$session.get("jwt") },
        })
        .then((res) => {
          this.$store.commit(
            "toast/showSuccess",
            this.$t("products.mainDialog.edit.success")
          );
          this.close(true);
        })
        .catch((err) => {
          this.$store.commit("alert/showErrorAxios", err);
        })
        .finally(() => {
          this.isLoadingBtn.btnSave = false;
        });
    },

    onInputAccount(val) {
      if (val && val.id) this.form.account = val.id;
      else this.form.account = val;
    },

    onInputFMReferent(val) {
      this.form.fm_referent = val;
    },

    onInputProjectsImpact(val) {
      this.form.projects_impact = val;
    },

    onInputBrandCollections(val) {
      this.form.brandCollections = val;
    },
  },
};
</script>

<style scoped></style>
